<template>
  <Content :search="false">
    <template v-slot:beforeSearch>
        <div class="flex items-center text-lg font-semibold dark:text-gray-300">
            <Button icon="arrow-left" @click="goBack" />
            <h2>{{selectedCategory.name}}</h2>
        </div>
    </template>
    
    <template v-slot:content>
        <div>
            <div class="sm:rounded-md mb-3">
                <ul role="list" class="">
                    <template v-if="selectedCategory">
                        <CategoryDetails :category="selectedCategory" />
                        
                        <CategoryPriceRates :category="selectedCategory" />

                        <template v-if="selectedCategory.subcategories_count > 0 && selectedCategory.subcategories">
                            <CategorySubcategories :categories="selectedCategory.subcategories" />
                        </template>
                    </template>
                </ul>
            </div>
        </div>
    </template>
  </Content>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

const CategoryDetails = defineAsyncComponent(() =>
  import('@/components/Settings/Category/Category/CategoryDetails'))
const CategoryPriceRates = defineAsyncComponent(() =>
  import('@/components/Settings/Category/Category/CategoryPriceRates'))
const CategorySubcategories = defineAsyncComponent(() =>
  import('@/components/Settings/Category/Category/CategorySubcategories'))

export default {
    name: "Category",
    components: {
        CategoryDetails,
        CategoryPriceRates,
        CategorySubcategories,
    },
    created() {
        this.$store.dispatch("getSingleCategory", this.$route.params.categoryId)
    },
    computed: {
        ...mapGetters({
            selectedCategory: "selectedCategory"
        })
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
    }
}
</script>

<style>

</style>