<template>
  <Content>
    <template v-slot:content>
      <template v-if="categoryProducts && categoryProducts.data.length > 0">
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <h2 class="font-semibold text-xl mb-5 -mt-4 dark:text-gray-300">{{categoryProducts.category.name}}</h2>

            <template v-for="product in categoryProducts.data" :key="product.id">
              <SingleCategoryProduct :product="product" />
            </template>
          </ul>
        </div>

        <Pagination :meta="categoryProducts.meta" :onPageChange="onPageChange" />
      </template>

      <template v-else>
          <p class="py-6 text-center dark:text-gray-300">
            Diemžēl nekas netika atrasts
          </p>
      </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";
import SingleCategoryProduct from "@/components/Settings/Category/Category/Products/SingleCategoryProduct";
import Pagination from "@/components/Components/Pagination";

export default {
    name: "CategoryProducts",
    components: {
        SingleCategoryProduct,
        Pagination,
    },
    data() {
        return {
            queryParams: null,
            // filter: {
            //   import_products: ""
            // }
        }
    },
    created() {
        this.$store.dispatch("setDataLoading", true)

        // Get query params from URL
        this.queryParams = this.$route.query

        // If there are no URL params set default params
        if(Object.keys(this.queryParams).length === 0) {
            this.$router.replace({
                query: {
                    page: Search.getCurrentPage(),
                    // import_products: "",
                    q: this.search
                }
            })
        }

        // Update component data from query prams
        this.setFiltersFromParams()
        this.searchCategoryProducts()
    },
    computed: {
        ...mapGetters({
            categoryProducts: "categoryProducts",
            search: 'search',
        })
    },
    watch: {
      search: function () {
        this.searchCategoryProducts()
      }
    },
    methods: {
        getItems() {
            this.$router.replace({
                query: {
                    id: this.$route.params.categoryId,
                    // import_products : this.filter.import_products,
                    page: 1,
                    q: this.queryParams.q
                }
            })

            this.queryParams = this.$route.query
            this.searchCategoryProducts()
        },

        setFiltersFromParams(){
            // this.filter.import_products = this.queryParams.import_products;
        },

        searchCategoryProducts: debounce(function () {
            this.$store.dispatch('getCategoryProducts', {queryParams: this.queryParams, id: this.$route.params.categoryId})
        }, 500),

        onPageChange(page) {
            this.searchCategoryProducts()
        },
    }
}
</script>

<style>

</style>