<template>
    <item-card :url="`/catalog/${product.id}`" contentClass="grid grid-cols-12 w-full">
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mb-2 md:mb-0">
          {{ product.name }}
        </h3>
      </template>

      <template v-slot:content>

          <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-2">
              <item-text title="#" :text="product.sku" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-2">
              <item-text title="Rādīt WEB" :text="product.allow_in_webshop ? 'Jā' : 'Nē'" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-2 xl:col-span-2">
              <item-text title="Ražotājs" :text="product.manufacturer" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-6 xl:col-span-6">
              <item-text title="Modelis" :text="product.model" />
          </div>

      </template>

    </item-card>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"

export default {
    name: "SingleCategoryProduct",
    components: {
        ItemCard,
        ItemText,
    },
    props: ["product"]
}
</script>

<style>

</style>