<template>
  <Content :filter="true" :showFilterDefault="true">
    <template v-slot:topbar>
      <AddCategory />
    </template>

    <template v-slot:filter>
      <div class="flex flex-wrap items-center">
        <div class="flex">
          <Radio name="isParent" v-model="filter.isParent" :items="[{ name: 'Visas', value: false}, {name: 'Tikai virskategorijas', value: true}]" @change="getItems" />
        </div>
      </div>
    </template>

    <template v-slot:content>
      <template v-if="categories && categories.data.length > 0">
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <template v-for="(category, index) in categories.data" :key="index">
              <SingleCategory @reset-categories="searchCategories" :category="category" />
            </template>
          </ul>
        </div>

        <Pagination :meta="categories.meta" :onPageChange="onPageChange" />
      </template>

      <template v-else>
          <p class="py-6 text-center dark:text-gray-300">
            Diemžēl nekas netika atrasts
          </p>
      </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";
import AddCategory from "@/components/Settings/Category/AddCategory";
import SingleCategory from "@/components/Settings/Category/SingleCategory";
import Pagination from "@/components/Components/Pagination";

import Radio from "@/components/Components/Radio"

export default {
  name: "Categories",
  components: {
    AddCategory,
    SingleCategory,
    Pagination,
    Radio,
  },
  data: () => ({
    filter: {
      isParent: false,  
    },
    queryParams: null,
  }),
  created() {
    this.$store.dispatch("setDataLoading", true)

    // Get query params from URL
    this.queryParams = this.$route.query

    // If there are no URL params set default params
    if(Object.keys(this.queryParams).length === 0) {
        this.$router.replace({
            query: {
                page: Search.getCurrentPage(),
                q: this.search
            }
        })
    }

    // Update component data from query prams
    this.setFiltersFromParams()
    this.searchCategories()
  },
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('clearSelectedCategory')
    this.$store.dispatch('resetSearch')
  },
  computed: {
    ...mapGetters({
      categories: 'categories',
      formsForDisplay: 'formsForDisplay',
      selectedCategory: 'selectedCategory',
      categoryForEdit: 'categoryForEdit',
      editableItem: 'editableItem',
      isLoading: 'loading',
      search: 'search',
    })
  },
  watch: {
    search: function () {
      this.searchCategories()
    }
  },
  methods: {
    getItems() {
          this.$router.replace({
            query: {
                is_parent : this.filter.isParent,
                page: 1,
                q: this.queryParams.q
            }
        })

        this.queryParams = this.$route.query

        this.searchCategories()
    },

    setFiltersFromParams(){
        this.filter.isParent = this.queryParams.is_parent == 'true' ? true : false;
    },

    searchCategories: debounce(function () {
      this.$store.dispatch('getCategories', this.queryParams)
    }, 500),

    onPageChange(page) {
      this.searchCategories()
    },
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
    hideAddCategoryForm(){
      this.$store.dispatch('removeAllFormsForDisplay')
    },
  }
}
</script>