<template>
  <li>
    <item-card contentClass="grid grid-cols-12" :url="`/categories/${category.id}`">
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mb-2 md:mb-0">
          {{ category.name }}
        </h3>
      </template>
      <template v-slot:buttons>

        <template v-if="category.subcategories_count && category.subcategories_count > 0">
          <div class="relative w-10 h-10">
            <template v-if="loading">
                <div class="absolute top-3 left-3">
                  <Loading/>
                </div>
            </template>
            <template v-else>
                <Button :icon="showSubcategory ? 'chevron-up' : 'chevron-down'" @click="getSubcategory"   tooltip="Apskatīt apakškategorijas" :tooltipRight="true" />
            </template>
          </div>
        </template>


        <template v-if="!showEditForm && !showAddForm">

          <Button icon="plus" @click="showAddForm=!showAddForm" />

        </template>
      </template>

      <template v-slot:content>

        <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
          <item-text title="Apakškategorijas" :text="category.subcategories_count" />
        </div>


          <div class="col-span-12 sm:col-span-6 lg:col-span-4 ">
              <ItemText title="Uzskaite ar unikāliem kodiem" :text="category.use_unique_codes ? 'Jā' : 'Nē'"/>
          </div>

          <div class="col-span-12 sm:col-span-6 lg:col-span-3 ">
              <ItemText title="Reversais PVN" :text="category.reverse_vat ? 'Jā' : 'Nē'"/>
          </div>

          <template v-if="category.products_count">
              <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
                  <item-text title="Produktu skaits" :text="category.products_count" />
              </div>
          </template>


        <template v-if="category.parent">
          <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-6">
            <item-text title="Virskategorija" :text="category.parent.name" />
          </div>
        </template>

      </template>

      <template v-slot:additional>

        <template v-if="!showEditForm && !showAddForm && showSubcategory && categorySubcategories">
          <div class="divide-y divide-gray-200 dark:divide-gray-700 pl-6">
            <template v-for="category in categorySubcategories" :key="category.id">
              <SingleSimpleCategory :category="category" />
            </template>
          </div>
        </template>

        <template v-if="showAddForm">
          <AddSubcategory @close-dialog="showAddForm = false" :category="category" />
        </template>

      </template>

    </item-card>
  </li>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Loading from "@/components/Components/Loading"
import AddSubcategory from "@/components/Settings/Category/AddSubcategory"
import SingleSimpleCategory from "@/components/Settings/Category/SingleSimpleCategory"

export default {
  name: "SingleCategory",
  components: {
    ItemCard,
    ItemText,
    Loading,
    AddSubcategory,
    SingleSimpleCategory,
  },
  props: [ 'category', 'subcategories' ],
  data() {
    return {
      showAddForm: false,
      showEditForm: false,
      showSubcategory: false,
      categorySubcategories: null,
      loading: false,
    }
  },
  methods: {
    closeEditForm(){
      this.showEditForm = false
      this.$emit('reset-categories')
    },
    getSubcategory() {
      this.showSubcategory = !this.showSubcategory;
      if (!this.categorySubcategories) {
        this.loading = true
        this.$store.dispatch("getSubcategories", this.category.id)
        .then(response => {
          this.categorySubcategories = response.data
          this.loading = false
        })
      }
    }
  }
}
</script>
