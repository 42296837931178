<template>
    <div class="w-full pb-2">
        <div class="flex hover:bg-gray-100 dark:hover:bg-gray-770 pl-3 py-2" @click="(event) => openCategory(event)">
            <div class="w-full grid grid-cols-12 rounded-lg cursor-pointer">
                <div class="col-span-12 sm:col-span-6">
                    <item-text title="Nosaukums" :text="category.name" />
                </div>
                <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                    <item-text title="Apakškategorijas" :text="category.subcategories_count" />
                </div>

                <template v-if="category.products_count">
                  <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                      <item-text title="Produktu skaits" :text="category.products_count" />
                  </div>
                </template>
            </div>
            <div class="ml-auto w-10 relative" ref="buttons">
                <template v-if="category.subcategories_count && category.subcategories_count > 0">
                    <template v-if="loading">
                        <div class="absolute top-3 left-3">
                          <Loading/>
                        </div>
                    </template>
                    <template v-else>
                        <Button :icon="showSubcategory ? 'chevron-up' : 'chevron-down'" @click="getSubcategory"   tooltip="Apskatīt apakškategorijas" :tooltipRight="true" />
                    </template>
                </template>
            </div>
        </div>

        <div class="w-full">
            <template v-if="!showEditForm && !showAddForm && showSubcategory && categorySubcategories">
                <div class="divide-y divide-gray-200 dark:divide-gray-700 pl-6">
                    <template v-for="category in categorySubcategories" :key="category.id">
                        <SingleSimpleCategory :category="category" />
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import ItemText from "@/components/Components/ItemText"
import SingleSimpleCategory from "@/components/Settings/Category/SingleSimpleCategory"
import Loading from "@/components/Components/Loading"

export default {
  name: "SingleSimpleCategory",
  components: {
    ItemText,
    SingleSimpleCategory,
    Loading,
  },
  props: [ 'category', 'subcategories' ],
  data() {
    return {
      showAddForm: false,
      showEditForm: false,
      showSubcategory: false,
      categorySubcategories: null,
      loading: false,
    }
  },
  methods: {
    getSubcategory() {
      this.showSubcategory = !this.showSubcategory;
      if (!this.categorySubcategories) {
        this.loading = true
        this.$store.dispatch("getSubcategories", this.category.id)
        .then(response => {
          this.categorySubcategories = response.data
          this.loading = false
        })
      }
    },
    openCategory(event) {
      const buttonsContainer = this.$refs.buttons

      if (buttonsContainer && event.target !== buttonsContainer && !buttonsContainer.contains(event.target) && event.target.nodeName !== 'BUTTON' && event.target.nodeName !== 'path' && event.target.nodeName !== 'svg') {
        this.$router.push(`/categories/${this.category.id}`)
      }
    }
  }
}
</script>
